import React from 'react';
import { Form, Field } from 'react-final-form'

import Layout from '../components/layout'
import SEO from "../components/seo"
import FooterArea from "../components/blocks/FooterArea";
import { Input, Textarea } from 'components/formHelper';
import axios from 'axios';


const ContactUS = () => {
  const [error, setError] = React.useState(false);
  const [successed, setSuccessed] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  function onSubmit(values, form) {
    setIsFetching(true);
    debugger
    const url = 'https://t99oynj1q2.execute-api.us-east-1.amazonaws.com/dev/api/send-email';
    axios({
      url,
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        from: values.full_name,
        to: 'galam.kw@gmail.com',
        subject: `Galam Contact Form - ${values.subject} <${values.email}>`,
        html: `
          <h4>Name: ${values.full_name}</h4>
          <h4>Email: ${values.email}</h4>
          <h4>${values.message}</h4>
        `
      },
    })
    .then((response) => {
      setError(false)
      setSuccessed(true)
      setIsFetching(false);
      form.reset();
    })
    .catch((error) => {
      setError(true)
      setSuccessed(false)
      setIsFetching(false);
    });
  }

  return (
    <Layout>
      <SEO title="تواصل معنا" />
      <section className="welcome-page sec-padding text-center p-relative o-hidden bg-gray">
        <div className="container">
          <div className="row welcome-text sec-padding flex-center">
            <div className="col-md-12 mb-20px z-index-1">
              <h1 className="color-blue">تواصل معنا</h1>
            </div>
            <div className="col-md-8 text-center">
              <img alt="img" src="/images/contact-us.png" className="ml-auto mr-auto" />
            </div>
          </div>
        </div>
        <div className="pattern p-absolute">
        </div>
      </section>
      <section className="contact-area sec-padding">
        <div className="container">
          <h1 className="title-h">تواصل معنا</h1>
          <div style={{ height: 20 }} />
          {/* <p className="title-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
          <div className="row">
            <div className="col-md-6">
              <div className="address">		
                <p className="mb-30px"><i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center icon-map" />الكويت - المرقاب - شارع عبد الله المبارك - برج NBT - الدور 16</p>
              </div>
              <div className="address">					
                <p className="mb-30px flex">
                  <i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center icon-phone" />
                <p className="mb-0" style={{ direction: 'ltr'}} >+96560765772</p>
                </p>
              </div>
              <div className="address">	
                <p className="mb-30px"><i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center icon-envelope" />galam.kw@gmail.com</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="messages" />
                      <div className="controls">
                        <div className="row"> 
                          <div className="col-md-6">
                          <Field name="full_name" component={Input} required placeholder="الاسم الكامل" />
                          </div>
                          <div className="col-md-6">
                            <Field name="email" component={Input} required type="email" placeholder="البريد الألكتروني" />
                          </div>
                          <div className="col-md-12">
                          <Field name="subject" component={Input} required placeholder="عنوان الرساله" />
                          </div>
                          <div className="col-md-12">
                          <Field name="message" component={Textarea} required placeholder="الرساله" />
                          </div>
                          <div className="col-md-12">
                          <div style={{ textAlign: 'right'}}>
                          {error && 
                          <div style={{ color: 'red' }} role="alert">
                            حدث خطاء في السيرفر
                          </div>}
                          {successed &&
                          <div style={{ color: '#1eda65'}} role="alert">
                            تم إرسال رسالتك بنجاح !
                          </div>}
                        </div>                            
                            <button disabled={isFetching} type="submit" className="main-btn btn-3 color-fff radius-50px bg-orange color-orange-hvr mt-2">إرسال</button>
                          </div>
                        </div>
                      </div>
                    </form>    
                  )}
                />
              </div>
            </div>
          </div>
        </div>   
      </section>
      <FooterArea />
    </Layout>
  );
}

export default ContactUS;